import React, { useRef, } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Container,
  Box,
  Divider,
} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';
import InvestorBanner from '../components/InvestorBanner/InvestorBanner';

const data = [
  {
    id: 1,
    title: "Information We Collect",
    description: "We collect various types of information in connection with the services we provide, including:",
    points: ["Personal Information: Name, email address, phone number, postal address, and other contact details.",
      "Financial Information: Bank account details, investment preferences, and transaction history.",
      "Usage Information: Details about your interactions with our Site, including IP address, browser type, access times, and pages viewed.",
      "Cookies and Tracking Technologies: Information collected through cookies, web beacons, and similar technologies to enhance user experience"]
  },
  {
    id: 2,
    title: "How We Use Your Information",
    description: "We use the information we collect to:",
    points: ["Provide, operate, and maintain our services.",
      "Improve, personalize, and expand our services",
      "Understand and analyze how you use our services.",
      "Develop new products, services, features, and functionality.",
      "Communicate with you, including for customer service, support, and to provide updates and information related to the Site.",
      "Process your transactions and manage your investments.",
      "Send you marketing and promotional communications.",
      "Detect and prevent fraud and other illegal activities.",
    ]
  }
]

const Privacy = () => {
  const footerRef = useRef(null);

  const handleContactUsClick = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:support@investofarms.com';
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "24px", background: `linear-gradient(90deg, rgba(209,230,226,1) 0%, rgba(234,238,236,1) 95%)`, overflowY: "scroll" }}>
      <Helmet>
        <title>InvestoFarms</title>
        <meta name="Investo Farms | Invest Today" content="Discover our Privacy Policy ensuring data security in agriculture investing. Learn how we protect your information throughout your journey." />
      </Helmet>
      <Box sx={{ height: "inherit" }}>
        <Navbar onContactUsClick={handleContactUsClick} />
      </Box>
      <Container maxWidth="lg" sx={{ height: "100%", }}>
        <Typography sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "bold", color: "#28947F", textAlign: "center" }}>
          Privacy Policy
        </Typography>
        <Box sx={{ height: "95%", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Introduction</Typography>
            <Typography sx={{ fontSize: "12px", }}>Welcome to the InvestoFarms website (the “Site”), operated by InvestoFarms Partners Private Limited (“InvestoFarms Partners Private Limited,” “InvestoFarms,” “we,” “us,” or “our”). We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Site.</Typography>
            <Divider />
          </Box>
          <Box>
            {data.map((item) => (
              <Box key={item.id} sx={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "16px" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>{item.title}</Typography>
                <Typography sx={{ fontSize: "12px" }}>{item.description}</Typography>
                <Box p={0.5}>
                  {item.points.map((point, index) => (
                    <Typography key={index} sx={{ fontSize: "12px" }}>
                      • {point}
                    </Typography>
                  ))}
                </Box>
              </Box>
            ))}
            <Divider />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Sharing Your Information</Typography>
            <Typography sx={{ fontSize: "12px" }}>We may share your information with third parties in the following circumstances:</Typography>
            <Box p={0.5} >
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Service Providers:</b> We may share your information with third-party vendors, consultants, and service providers who perform services on our behalf.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Business Transfers:</b>  We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Protection of Rights:</b>We may disclose your information to protect and defend our rights or property, or the safety of our users or others
              </Typography>

            </Box>
          </Box>
          
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Security of Your Information</Typography>
            <Typography sx={{ fontSize: "12px", }}> We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</Typography>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Your Data Protection Rights</Typography>
            <Typography sx={{ fontSize: "12px", }}>Depending on your location, you may have the following rights regarding your personal information:</Typography>
            <Box p={0.5} >
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Access:</b> The right to request copies of your personal information.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Rectification:</b> The right to request that we correct any information you believe is inaccurate or incomplete.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Erasure:</b>The right to request that we erase your personal information, under certain conditions.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Restrict Processing:</b>The right to request that we restrict the processing of your personal information, under certain conditions.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Object to Processing:</b>The right to object to our processing of your personal information, under certain conditions.
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                <b>• Data Portability:</b>The right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Children's Privacy</Typography>
            <Typography sx={{ fontSize: "12px", }}>Our Site is not intended for children under the age of 18. We do not knowingly collect or solicit personal information from children under 18. If we learn that we have collected personal information from a child under age 18, we will delete that information as quickly as possible.</Typography>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Contact Us</Typography>
            <Typography sx={{ fontSize: "12px", }}>If you have any questions or concerns about this Privacy Policy, please contact us at:</Typography>
            <Typography sx={{ fontSize: "12px", }}>InvestoFarms Email: <b style={{ cursor: "pointer" }} onClick={handleEmailClick}>support@investofarms.com</b></Typography>
            <Typography sx={{ fontSize: "12px", }}>By using our Site, you consent to our Privacy Policy.</Typography>
          </Box>
        </Box>
      </Container>
      <Box sx={{ height: "inherit", mt: "30px" }}>
        <InvestorBanner />
        <Footer ref={footerRef} />
      </Box>
    </Box>
  );
};

export default Privacy;
