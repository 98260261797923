import React, { useRef, } from 'react';
import { Helmet } from 'react-helmet';
import {
    Typography,
    Container,
    Box,
    Divider,
} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';
import InvestorBanner from '../components/InvestorBanner/InvestorBanner';

const Refund = () => {
    const footerRef = useRef(null);

    const handleContactUsClick = () => {
        if (footerRef.current) {
            footerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };



    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "24px", background: `linear-gradient(90deg, rgba(209,230,226,1) 0%, rgba(234,238,236,1) 95%)`, overflowY: "scroll" }}>
            <Helmet>
                <title>InvestoFarms</title>
                <meta name="Investo Farms | Invest Today" content="Explore our Terms & Conditions for agriculture investing. Understand guidelines and responsibilities for a secure financial journey." />
            </Helmet>
            <Box sx={{ height: "inherit" }}>
                <Navbar onContactUsClick={handleContactUsClick} />
            </Box>
            <Container maxWidth="lg" sx={{ height: "100%", }}>
                <Typography sx={{ fontSize: { xs: "20px", md: "25px" }, fontWeight: "bold", color: "#28947F", textAlign: "center" }}>
                    Refund Policy
                </Typography>
                <Box>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", }}> Investofarms Refund Policy
                    </Typography>
                    {/* <br></br> */}
                    <Divider sx={{ m: "10px 0px 10px 0px" }} />
                    <Typography sx={{ fontSize: "12px", }}>
                    We understand that circumstances may change, and you may need to withdraw your investment. InvestoFarms Partners Private Limited, also known as InvestoFarms, offers flexibility in this regard.
                        Refund Processing: Upon receiving a refund request, InvestoFarms Partners Private Limited will initiate the refund process.
                        Refund Amount: A processing fee of <span style={{ textDecoration:"underline dotted", fontWeight:"bold"}}>₹2,000 </span>will be deducted from the total investment amount to cover administrative and documentation costs. The remaining amount will be refunded to the investor.
                        Refund Timeline: The refund amount will be processed and transferred to the investor's account within 7 working days of receiving the refund request.
                        No Returns: Please note that this refund policy does not guarantee any returns on investment. The refund amount is based on the initial investment minus the processing fee.
                    </Typography>
                    {/* <br></br> */}
                    <Divider sx={{ m: "10px 0px 10px 0px" }} />
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold",}}>Additional Terms and Conditions:</Typography>
                    <Box p={0.5} >
                        <Typography sx={{ fontSize: "12px" }}>
                            *  The refund policy is subject to any applicable laws and regulations.
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                            * InvestoFarms Partners Private Limited (referred to as "InvestoFarms") reserves the right to modify this policy at its discretion.
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                        By investing in InvestoFarms Partners Private Limited, known as InvestoFarms, you acknowledge and agree to the terms of this refund policy.
                            <Divider sx={{ m: "10px 0px 10px 0px" }} />
                            <span style={{fontWeight:"bold"}}>Disclaimer:</span> This refund policy is a general template and may require modifications based on specific legal and regulatory requirements. It is advisable to consult with a legal professional to ensure compliance with applicable laws.
                        </Typography>

                    </Box>
                </Box>
            </Container>
            <Box sx={{ height: "inherit" }}>
                <InvestorBanner />
                <Footer ref={footerRef} />
            </Box>
        </Box>
    );
};

export default Refund;
