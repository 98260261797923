import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import './style.css';

// import required modules
import { FreeMode, Pagination, Autoplay } from 'swiper/modules';
import { Box, Card, CardMedia, Typography } from '@mui/material';
import TaxIcon from "../../assets/images/TaxIcon.svg";
import InvestIcon from "../../assets/images/investmentIcon.svg";
import higherIcon from "../../assets/images/higherReturnIcon.svg";
import TechIcon from "../../assets/images/TechnologyIcon.svg";
import FramIcon from "../../assets/images/FramingIcon.svg";
import exportIcon from "../../assets/images/expertIcon.svg";


const CardData = [
  { id: 1, name: "Tax-Free Returns", icon: TaxIcon },
  { id: 2, name: "Minimum Investment Threshold", icon: InvestIcon },
  { id: 3, name: "Higher Return Potential", icon: higherIcon },
  { id: 4, name: "Technology Driven Platform", icon: TechIcon },
  { id: 5, name: "Precision Farming", icon: FramIcon },
  { id: 6, name: "Expert Partner Network", icon: exportIcon },
];
const customPaginationStyle = `
  .swiper-pagination-bullet-active {
    background-color: #EBB912;
  }
`;


function InvestorSlider() {
  const [focusedSlide, setFocusedSlide] = useState(null);

  return (
    <Box sx={{ background: "transparent", p: 1 }}>
      <style>{customPaginationStyle}</style>
      <Swiper
        slidesPerView={3}
        // spaceBetween={10}
        freeMode={true}
        pagination={{
          clickable: true,
          color: "red",
        }}
        modules={[FreeMode, Pagination, Autoplay]}
        autoplay={{ delay: 1500 }}
        loop={true}
        onSlideChange={(swiper) => setFocusedSlide(swiper.realIndex)}

      >
        {CardData.map((item, index) => (
          <SwiperSlide key={item.id}>

            <Card sx={{ p: 1, width: { xs: "100px", md: "185px" }, height: { xs: "120px", md: "220px" }, background: focusedSlide === index ? "#28947F" : "#fff", boxShadow: `rgba(40, 148, 127, 0.24) 0px 3px 8px`, borderRadius: "8px", display: "flex", alignItems: "center", m: "30px" , transition: "background-color 0.3s ease",}}>
              <Box sx={{ width: "inherit", height: "90%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center", }}>
                <Box sx={{ width: { xs: "40px", md: "80px" }, height: { xs: "40px", md: "80px" } }}>
                  <CardMedia
                    sx={{ height: "100%", width: "100%", objectFit: "cover" }}
                    image={item.icon}
                    title={item.name}
                  />
                </Box>
                <Typography sx={{ fontWeight: "bold", fontSize: { xs: "10px", md: "14px" }, color: focusedSlide === index ? "#fff" : "#28947F", textAlign: "center" }}>{item.name}</Typography>
              </Box>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
export default InvestorSlider