import React, { useRef, } from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Container,
  Box,
  Divider,
} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';
import InvestorBanner from '../components/InvestorBanner/InvestorBanner';

const TermsAndConditions = () => {
  const footerRef = useRef(null);

  const handleContactUsClick = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };



  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "24px", background: `linear-gradient(90deg, rgba(209,230,226,1) 0%, rgba(234,238,236,1) 95%)`, overflowY: "scroll" }}>
      <Helmet>
        <title>InvestoFarms</title>
        <meta name="Investo Farms | Invest Today" content="Explore our Terms & Conditions for agriculture investing. Understand guidelines and responsibilities for a secure financial journey." />
      </Helmet>
      <Box sx={{ height: "inherit" }}>
        <Navbar onContactUsClick={handleContactUsClick} />
      </Box>
      <Container maxWidth="lg" sx={{ height: "100%", }}>
        <Typography sx={{ fontSize: { xs: "20px", md: "25px" }, fontWeight: "bold", color: "#28947F", textAlign: "center" }}>
          Terms & Conditions
        </Typography>
        <Box>
        <Typography sx={{ fontSize: "12px", }}>Welcome to the InvestoFarms website, which is owned and administered by InvestoFarms Partners Private Limited ("InvestoFarms," "we," "us," or "our"). You accept and agree to be governed by the following terms and conditions of usage (the "Terms of Use") by accessing or using the Site. Before using the Site, please carefully read these Terms of Use.
        </Typography>
        <br></br>
        <Typography sx={{ fontSize: "12px", }}>
          Acceptance of the Conditions You accept that these Terms of Use, along with any applicable laws and regulations, will apply to you when you access or use the Site. It is forbidden for you to use or access this Site if you disagree with any of these terms. The content on this website is shielded by relevant trademark and copyright laws.
        </Typography>
        {/* <br></br> */}
        <Divider sx={{m:"10px 0px 10px 0px"}}/>
        <Typography sx={{ fontSize: "12px", }}>Use Licence Permission is given to download one copy of the content (software or information) from the InvestoFarms website, operated by InvestoFarms Partners Private Limited, for short-term, personal, non-commercial reading only. This is merely a licence grant, not a title transfer. As a result, you are not permitted to:</Typography>
        <br></br>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>Sharing Your Information</Typography>
          <Typography sx={{ fontSize: "15px" }}>We may share your information with third parties in the following circumstances:</Typography>
          <Box p={0.5} >
            <Typography sx={{ fontSize: "12px" }}>
              ●	alter or duplicate the materials;
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              ●	use them for any kind of commercial endeavour or public exhibition, whether for profit or not;
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              ●	Make an effort to reverse engineer or decompile any software found on the InvestoFarms Partners Private Limited website, also referred to as InvestoFarms;
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              ●	Either remove any proprietary or copyright notices from the materials, or transfer them to another individual or "mirror" them on a different server.
            </Typography>

          </Box>
        </Box>
        <Typography sx={{ fontSize: "15px", }}>
          This licence will instantly expire if you break any of these guidelines, and InvestoFarms Partners Private Limited (hereinafter referred to as "InvestoFarms") reserves the right to do so at any moment. You shall destroy any downloaded materials in your possession, whether in printed or electronic format, upon ending your viewing of these materials or upon the termination of this licence.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Notice of Disclaimer:</b> The information on the InvestoFarms Partners Private Limited website (known as "InvestoFarms") is provided "as is." No warranties are made by InvestoFarms Partners Private Limited, either express or implied. All other warranties, including implied warranties or conditions of merchantability, fitness for a particular purpose, and non-infringement of intellectual property or other rights violations, are hereby disclaimed and negated.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Restrictions:</b> Even though InvestoFarms Partners Private Limited (known as InvestoFarms) or an authorised representative of InvestoFarms has been informed orally or in writing of the possibility of such damage, InvestoFarms Partners Private Limited or its suppliers shall in no case be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on InvestoFarms' Site.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Corrections and Updates:</b> There may be typographical, technical, or photographic errors in the contents that appear on the InvestoFarms website. None of the content on InvestoFarms website, operated by InvestoFarms Partners Private Limited (hereinafter referred to as "InvestoFarms"), is guaranteed to be true, comprehensive, or up to date. InvestoFarms reserves the right, at any moment and without prior notice, to modify the content on its website. However, InvestoFarms makes no promises regarding updating the content.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Links:</b> InvestoFarms Partners Private Limited (commonly known as InvestoFarms) disclaims any liability for the contents of any linked website, as it has not examined all of the websites that link to its website. Any link does not indicate that InvestoFarms Partners Private Limited approves of the website. The user assumes all risk while using any such linked website.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Modifications to the Site Terms of Use:</b> These Terms of Use for this Site may be changed by InvestoFarms Partners Private Limited (doing business as InvestoFarms) at any time and without prior notice. By using this site, you accept to be legally bound by the most recent version of these Terms of Use.
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Regulation of Law and Authority:</b> The laws of India shall govern and be applied to the interpretation of these Terms of Use. Ambala, Harayana, India courts will have exclusive jurisdiction over any dispute arising out of or relating to these Terms of Use.
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>
        Get in touch with us Please send an email to support@investofarms.com with any issues you may have regarding our terms of use.
        </Typography>
        </Box>
      </Container>
      <Box sx={{ height: "inherit" }}>
        <InvestorBanner />
        <Footer ref={footerRef} />
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
